.my-masonry-grid_column {
    display: inline-block;
    vertical-align: top;
}

body {
    background: transparent!important;
    font-size: 16px;
}

a {
  color: #0a51d4;
  text-decoration: underline;
}

a:hover {
  color: #19A8E0
}

:root {
    --light: 80;
    /* the threshold at which colors are considered "light." Range: integers from 0 to 100,
  recommended 50 - 70 */
    --threshold: 60;
}

section {
    mix-blend-mode: screen;
    display: block;
    position: relative;
    background-color: #1C437E;
}

.auto-color {
    mix-blend-mode: difference;
    color: white;
    z-index: 3;
}

.section-overlay {
  z-index: 4;
}

.z-top {
    z-index: 99999!important;
}

.scale {
  transition: all .2s ease-in-out;
}

.scale:hover {
  transform: scale(1.02);
}
